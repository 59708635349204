// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MB-Container {
  position: absolute;
  top: 85px;
  left: 0;
  right: 0;
  bottom: 90px;
  text-align: left;
  margin-left: 60px;
  margin-right: 60px;
  overflow-y: auto;
  scrollbar-color: #e8e87f50 #1f2329;
}

.MB-Message-Line {
  position: absolute;
  left: 0;
  right: 0;
}

.MB-Message-User {
  position: absolute;
  right: 10px;
  background-color: #808080;
  opacity: 0.7;
  padding: 5px;
  margin: 2px;
  border-radius: 10px;
  max-width: 55%;
}

.MB-Message-AI {
  position: absolute;
  left: 10px;
  background-color: #d3d3d3;
  opacity: 0.5;
  padding: 5px;
  margin: 2px;
  border-radius: 10px;
  max-width: 55%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/MessageBox.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,QAAQ;AACV;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".MB-Container {\n  position: absolute;\n  top: 85px;\n  left: 0;\n  right: 0;\n  bottom: 90px;\n  text-align: left;\n  margin-left: 60px;\n  margin-right: 60px;\n  overflow-y: auto;\n  scrollbar-color: #e8e87f50 #1f2329;\n}\n\n.MB-Message-Line {\n  position: absolute;\n  left: 0;\n  right: 0;\n}\n\n.MB-Message-User {\n  position: absolute;\n  right: 10px;\n  background-color: #808080;\n  opacity: 0.7;\n  padding: 5px;\n  margin: 2px;\n  border-radius: 10px;\n  max-width: 55%;\n}\n\n.MB-Message-AI {\n  position: absolute;\n  left: 10px;\n  background-color: #d3d3d3;\n  opacity: 0.5;\n  padding: 5px;\n  margin: 2px;\n  border-radius: 10px;\n  max-width: 55%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
