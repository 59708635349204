import React from "react";
import "../styles/Toolbar.css";
import SelectInput from "./SelectInput";
import ToolbarProperties from "../properties/ToolbarProperties";
import StorageKeys from "../enums/StorageKeys";

export default function Toolbar(props: ToolbarProperties) {
  const [seconds, setSeconds] = React.useState<number>(0);

  setInterval(() => {
    const parts = localStorage.getItem(StorageKeys.JwtToken)?.split(".");

    if (parts !== undefined && parts.length === 3) {
      const tokenPayload = JSON.parse(atob(parts[1]));
      const now = Math.ceil(Date.now() / 1000);
      const secondsRemaining = tokenPayload.exp - now;

      if (secondsRemaining >= 0) {
        setSeconds(tokenPayload.exp - now);
      }
    }
  }, 1000);

  return (
    <div className="TB-Container">
      <a
        className="TB-Link"
        href="https://soniverse.de"
        target="_blank"
        rel="noreferrer"
      >
        # Soniverse AI - {seconds}
      </a>

      {props.models !== undefined &&
        props.model !== undefined &&
        props.setModel !== undefined && (
          <div className="TP-Select-Model">
            <SelectInput
              values={props.models}
              value={props.model}
              setValue={props.setModel}
            />
          </div>
        )}
    </div>
  );
}
