import React, { useEffect, useRef, useState } from "react";
import "../styles/MessageBox.css";
import MessageBoxProperties from "../properties/MessageBoxProperties";
import ChatType from "../types/ChatType";
import { useTranslation } from "react-i18next";
import { MessageUser } from "../enums/MessageUser";

export default function MessageBox(props: MessageBoxProperties) {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const chatRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [messages, setMessages] = useState<ChatType[]>([]);
  const [chatHeights, setChatHeights] = useState<number[]>([]);

  useEffect(() => {
    const tempMessage = [
      ...props.messages,
      {
        username: MessageUser.AI.toString(),
        message: t("initial-ai-message"),
        timestamp: 0,
      },
    ];
    tempMessage.sort((n1, n2) => {
      if (n1.timestamp > n2.timestamp) {
        return 1;
      }

      if (n1.timestamp < n2.timestamp) {
        return -1;
      }

      return 0;
    });

    setMessages(tempMessage);
  }, [props.messages, t]);

  useEffect(() => {
    const calculatedHeight: number[] = [];
    chatRefs.current.forEach((item, index, array) => {
      if (array[index - 1] !== undefined) {
        calculatedHeight[index] =
          (array[index - 1]?.scrollHeight ?? 0) + calculatedHeight[index - 1];
      } else {
        calculatedHeight[index] = 0;
      }
    });

    setChatHeights(calculatedHeight);
  }, [chatRefs, messages]);

  useEffect(() => {
    containerRef.current?.scroll({
      top: chatHeights[chatHeights.length - 1],
      behavior: "smooth",
    });
  }, [chatHeights]);

  return (
    <div className="MB-Container" ref={containerRef}>
      {messages.map((chat, index) => (
        <div
          ref={(el) => (chatRefs.current[index] = el)}
          key={index}
          style={{ top: chatHeights[index] ?? 0 }}
          className="MB-Message-Line"
        >
          {chat.username === "user" && (
            <div className="MB-Message-User">{chat.message}</div>
          )}
          {chat.username === "ai" && (
            <div className="MB-Message-AI">{chat.message}</div>
          )}
        </div>
      ))}
    </div>
  );
}
