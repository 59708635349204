import React, { useEffect, useRef, useState } from "react";
import { FaPlay, FaTrash } from "react-icons/fa";
import "../styles/SearchBar.css";
import SearchBarProperties from "../properties/SearchBarProperties";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";

export default function SearchBar(props: SearchBarProperties) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  const clearInput = () => {
    setSearchTerm("");
  };

  const search = () => {
    props.searchFunction(searchTerm);
    setSearchTerm("");
  };

  useEffect(() => {
    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        props.searchFunction(inputRef.current?.value ?? "");
        setSearchTerm("");
      }
    };

    window.addEventListener(
      "keydown",
      handleKeyDown as unknown as EventListener,
    );

    return () => {
      window.removeEventListener(
        "keydown",
        handleKeyDown as unknown as EventListener,
      );
    };
  }, [props]);

  return (
    <div className="SB-Container">
      <input
        ref={inputRef}
        className="SB-Input"
        type="text"
        placeholder={t("search-placeholder")}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {props.isLoading && (
        <ClipLoader className="SB-Loader" color="white" size={25} />
      )}
      {!props.isLoading && (
        <FaPlay className="SB-Search" onClick={() => search()} />
      )}
      <FaTrash className="SB-Clear" onClick={() => clearInput()} />
    </div>
  );
}
