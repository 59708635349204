// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SB-Container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 25px;
}

.SB-Input {
  position: absolute;
  padding: 10px;
  font-size: 12px;
  font-weight: 600;
  bottom: 0;
  left: 0;
  right: 64px;
  border-radius: 3px;
}

.SB-Search {
  position: absolute;
  bottom: 0;
  right: 32px;
  font-size: 20px;
  margin-bottom: 9px;
  cursor: pointer;
  color: #ffffff;
}

.SB-Loader {
  position: absolute;
  bottom: 0;
  right: 28px;
  margin-bottom: 4px;
}

.SB-Clear {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 20px;
  margin-bottom: 9px;
  cursor: pointer;
  color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/styles/SearchBar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,SAAS;EACT,OAAO;EACP,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,cAAc;AAChB","sourcesContent":[".SB-Container {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  margin: 25px;\n}\n\n.SB-Input {\n  position: absolute;\n  padding: 10px;\n  font-size: 12px;\n  font-weight: 600;\n  bottom: 0;\n  left: 0;\n  right: 64px;\n  border-radius: 3px;\n}\n\n.SB-Search {\n  position: absolute;\n  bottom: 0;\n  right: 32px;\n  font-size: 20px;\n  margin-bottom: 9px;\n  cursor: pointer;\n  color: #ffffff;\n}\n\n.SB-Loader {\n  position: absolute;\n  bottom: 0;\n  right: 28px;\n  margin-bottom: 4px;\n}\n\n.SB-Clear {\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  font-size: 20px;\n  margin-bottom: 9px;\n  cursor: pointer;\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
