import logo from "../logo.svg";
import React from "react";
import "../styles/Background.css";

export default function Background() {
  return (
    <div className="BG-Container">
      <img src={logo} className="BG-Logo" alt="logo" />
    </div>
  );
}
