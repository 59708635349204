// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TB-Container {
  position: absolute;
  padding: 15px;
  background-color: #1f2329;
  left: 0;
  right: 0;
  top: 0;
  text-align: left;
}

.TB-Link {
  text-decoration: none;
  color: #e8e87f;
  font-size: 20px;
  font-weight: bold;
}

.TP-Select-Model {
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Toolbar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,OAAO;EACP,QAAQ;EACR,MAAM;EACN,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,aAAa;AACf","sourcesContent":[".TB-Container {\n  position: absolute;\n  padding: 15px;\n  background-color: #1f2329;\n  left: 0;\n  right: 0;\n  top: 0;\n  text-align: left;\n}\n\n.TB-Link {\n  text-decoration: none;\n  color: #e8e87f;\n  font-size: 20px;\n  font-weight: bold;\n}\n\n.TP-Select-Model {\n  position: absolute;\n  top: 0;\n  right: 0;\n  padding: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
