import {
  OllamaApi,
  OllamaOllamaGenerateOutputDTO,
} from "../../generated/soniAiBackend";
import { useSoniAiBackend } from "./SoniAiBackend";
import StorageKeys from "../../enums/StorageKeys";
import OllamaGenerateBodyType from "../../types/OllamaGenerateBodyType";

interface SoniAiBackendOllamaGenerateProperties {
  execute: boolean;
  basePath: string | undefined | null;
  body: OllamaGenerateBodyType;
}

export const useSoniAiBackendOllamaGenerate = (
  props: SoniAiBackendOllamaGenerateProperties,
) => {
  const soniAiBackendOllamaGenerate = new OllamaApi(
    undefined,
    props.basePath ?? "",
  );

  if (props.body.prompt === "") {
    props.execute = false;
  }

  return useSoniAiBackend<OllamaOllamaGenerateOutputDTO>({
    execute: props.execute,
    basePath: props.basePath,
    apiCall: soniAiBackendOllamaGenerate.apiOllamaGeneratePost,
    options: {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(StorageKeys.JwtToken),
      },
    },
    uriVariables: [JSON.stringify(props.body)],
  });
};
