import {
  LoginCheckApi,
  LoginCheckPost200Response,
} from "../../generated/soniAiBackend";
import { useSoniAiBackend } from "./SoniAiBackend";

interface SoniAiBackendLoginProperties {
  execute: boolean;
  basePath: string | undefined | null;
  email: string;
  password: string;
}

export const useSoniAiBackendLogin = (props: SoniAiBackendLoginProperties) => {
  const soniAiBackendLogin = new LoginCheckApi(undefined, props.basePath ?? "");

  return useSoniAiBackend<LoginCheckPost200Response>({
    execute: props.execute,
    basePath: props.basePath,
    apiCall: soniAiBackendLogin.loginCheckPost,
    options: { email: props.email, password: props.password },
  });
};
