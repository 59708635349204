/* tslint:disable */
/* eslint-disable */
/**
 * Soni AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
import type { RequestArgs } from "./base";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  BaseAPI,
  RequiredError,
  operationServerMap,
} from "./base";

/**
 *
 * @export
 * @interface ApiBrainKnowGetCollection200Response
 */
export interface ApiBrainKnowGetCollection200Response {
  /**
   *
   * @type {Array<BrainBrainKnowDTOJsonld>}
   * @memberof ApiBrainKnowGetCollection200Response
   */
  "hydra:member": Array<BrainBrainKnowDTOJsonld>;
  /**
   *
   * @type {number}
   * @memberof ApiBrainKnowGetCollection200Response
   */
  "hydra:totalItems"?: number;
  /**
   *
   * @type {ApiBrainKnowGetCollection200ResponseHydraView}
   * @memberof ApiBrainKnowGetCollection200Response
   */
  "hydra:view"?: ApiBrainKnowGetCollection200ResponseHydraView;
  /**
   *
   * @type {ApiBrainKnowGetCollection200ResponseHydraSearch}
   * @memberof ApiBrainKnowGetCollection200Response
   */
  "hydra:search"?: ApiBrainKnowGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiBrainKnowGetCollection200ResponseHydraSearch
 */
export interface ApiBrainKnowGetCollection200ResponseHydraSearch {
  /**
   *
   * @type {string}
   * @memberof ApiBrainKnowGetCollection200ResponseHydraSearch
   */
  "@type"?: string;
  /**
   *
   * @type {string}
   * @memberof ApiBrainKnowGetCollection200ResponseHydraSearch
   */
  "hydra:template"?: string;
  /**
   *
   * @type {string}
   * @memberof ApiBrainKnowGetCollection200ResponseHydraSearch
   */
  "hydra:variableRepresentation"?: string;
  /**
   *
   * @type {Array<ApiBrainKnowGetCollection200ResponseHydraSearchHydraMappingInner>}
   * @memberof ApiBrainKnowGetCollection200ResponseHydraSearch
   */
  "hydra:mapping"?: Array<ApiBrainKnowGetCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 *
 * @export
 * @interface ApiBrainKnowGetCollection200ResponseHydraSearchHydraMappingInner
 */
export interface ApiBrainKnowGetCollection200ResponseHydraSearchHydraMappingInner {
  /**
   *
   * @type {string}
   * @memberof ApiBrainKnowGetCollection200ResponseHydraSearchHydraMappingInner
   */
  "@type"?: string;
  /**
   *
   * @type {string}
   * @memberof ApiBrainKnowGetCollection200ResponseHydraSearchHydraMappingInner
   */
  variable?: string;
  /**
   *
   * @type {string}
   * @memberof ApiBrainKnowGetCollection200ResponseHydraSearchHydraMappingInner
   */
  property?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ApiBrainKnowGetCollection200ResponseHydraSearchHydraMappingInner
   */
  required?: boolean;
}
/**
 *
 * @export
 * @interface ApiBrainKnowGetCollection200ResponseHydraView
 */
export interface ApiBrainKnowGetCollection200ResponseHydraView {
  /**
   *
   * @type {string}
   * @memberof ApiBrainKnowGetCollection200ResponseHydraView
   */
  "@id"?: string;
  /**
   *
   * @type {string}
   * @memberof ApiBrainKnowGetCollection200ResponseHydraView
   */
  "@type"?: string;
  /**
   *
   * @type {string}
   * @memberof ApiBrainKnowGetCollection200ResponseHydraView
   */
  "hydra:first"?: string;
  /**
   *
   * @type {string}
   * @memberof ApiBrainKnowGetCollection200ResponseHydraView
   */
  "hydra:last"?: string;
  /**
   *
   * @type {string}
   * @memberof ApiBrainKnowGetCollection200ResponseHydraView
   */
  "hydra:previous"?: string;
  /**
   *
   * @type {string}
   * @memberof ApiBrainKnowGetCollection200ResponseHydraView
   */
  "hydra:next"?: string;
}
/**
 *
 * @export
 * @interface ApiOllamaListLocalModelsGetCollection200Response
 */
export interface ApiOllamaListLocalModelsGetCollection200Response {
  /**
   *
   * @type {Array<OllamaOllamaListLocalModelsDTOJsonld>}
   * @memberof ApiOllamaListLocalModelsGetCollection200Response
   */
  "hydra:member": Array<OllamaOllamaListLocalModelsDTOJsonld>;
  /**
   *
   * @type {number}
   * @memberof ApiOllamaListLocalModelsGetCollection200Response
   */
  "hydra:totalItems"?: number;
  /**
   *
   * @type {ApiBrainKnowGetCollection200ResponseHydraView}
   * @memberof ApiOllamaListLocalModelsGetCollection200Response
   */
  "hydra:view"?: ApiBrainKnowGetCollection200ResponseHydraView;
  /**
   *
   * @type {ApiBrainKnowGetCollection200ResponseHydraSearch}
   * @memberof ApiOllamaListLocalModelsGetCollection200Response
   */
  "hydra:search"?: ApiBrainKnowGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface BrainBrainKnowDTO
 */
export interface BrainBrainKnowDTO {
  /**
   *
   * @type {string}
   * @memberof BrainBrainKnowDTO
   */
  uuid?: string;
  /**
   *
   * @type {BrainMetadataDTO}
   * @memberof BrainBrainKnowDTO
   */
  metadata?: BrainMetadataDTO;
}
/**
 *
 * @export
 * @interface BrainBrainKnowDTOJsonld
 */
export interface BrainBrainKnowDTOJsonld {
  /**
   *
   * @type {string}
   * @memberof BrainBrainKnowDTOJsonld
   */
  "@id"?: string;
  /**
   *
   * @type {string}
   * @memberof BrainBrainKnowDTOJsonld
   */
  "@type"?: string;
  /**
   *
   * @type {string}
   * @memberof BrainBrainKnowDTOJsonld
   */
  uuid?: string;
  /**
   *
   * @type {BrainMetadataDTOJsonld}
   * @memberof BrainBrainKnowDTOJsonld
   */
  metadata?: BrainMetadataDTOJsonld;
}
/**
 *
 * @export
 * @interface BrainBrainLearnDTO
 */
export interface BrainBrainLearnDTO {
  /**
   *
   * @type {string}
   * @memberof BrainBrainLearnDTO
   */
  name?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof BrainBrainLearnDTO
   */
  content?: Array<string>;
}
/**
 *
 * @export
 * @interface BrainBrainLearnDTOJsonld
 */
export interface BrainBrainLearnDTOJsonld {
  /**
   *
   * @type {string}
   * @memberof BrainBrainLearnDTOJsonld
   */
  name?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof BrainBrainLearnDTOJsonld
   */
  content?: Array<string>;
}
/**
 *
 * @export
 * @interface BrainMetadataDTO
 */
export interface BrainMetadataDTO {
  /**
   *
   * @type {string}
   * @memberof BrainMetadataDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof BrainMetadataDTO
   */
  user?: string;
  /**
   *
   * @type {string}
   * @memberof BrainMetadataDTO
   */
  timezone?: string;
  /**
   *
   * @type {number}
   * @memberof BrainMetadataDTO
   */
  timestamp?: number;
  /**
   *
   * @type {number}
   * @memberof BrainMetadataDTO
   */
  insertIndex?: number;
}
/**
 *
 * @export
 * @interface BrainMetadataDTOJsonld
 */
export interface BrainMetadataDTOJsonld {
  /**
   *
   * @type {BrainResponseDTOJsonldContext}
   * @memberof BrainMetadataDTOJsonld
   */
  "@context"?: BrainResponseDTOJsonldContext;
  /**
   *
   * @type {string}
   * @memberof BrainMetadataDTOJsonld
   */
  "@id"?: string;
  /**
   *
   * @type {string}
   * @memberof BrainMetadataDTOJsonld
   */
  "@type"?: string;
  /**
   *
   * @type {string}
   * @memberof BrainMetadataDTOJsonld
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof BrainMetadataDTOJsonld
   */
  user?: string;
  /**
   *
   * @type {string}
   * @memberof BrainMetadataDTOJsonld
   */
  timezone?: string;
  /**
   *
   * @type {number}
   * @memberof BrainMetadataDTOJsonld
   */
  timestamp?: number;
  /**
   *
   * @type {number}
   * @memberof BrainMetadataDTOJsonld
   */
  insertIndex?: number;
}
/**
 *
 * @export
 * @interface BrainResponseDTO
 */
export interface BrainResponseDTO {
  /**
   *
   * @type {string}
   * @memberof BrainResponseDTO
   */
  message?: string;
}
/**
 *
 * @export
 * @interface BrainResponseDTOJsonld
 */
export interface BrainResponseDTOJsonld {
  /**
   *
   * @type {BrainResponseDTOJsonldContext}
   * @memberof BrainResponseDTOJsonld
   */
  "@context"?: BrainResponseDTOJsonldContext;
  /**
   *
   * @type {string}
   * @memberof BrainResponseDTOJsonld
   */
  "@id"?: string;
  /**
   *
   * @type {string}
   * @memberof BrainResponseDTOJsonld
   */
  "@type"?: string;
  /**
   *
   * @type {string}
   * @memberof BrainResponseDTOJsonld
   */
  message?: string;
}
/**
 * @type BrainResponseDTOJsonldContext
 * @export
 */
export type BrainResponseDTOJsonldContext =
  | BrainResponseDTOJsonldContextOneOf
  | string;

/**
 *
 * @export
 * @interface BrainResponseDTOJsonldContextOneOf
 */
export interface BrainResponseDTOJsonldContextOneOf {
  [key: string]: any;

  /**
   *
   * @type {string}
   * @memberof BrainResponseDTOJsonldContextOneOf
   */
  "@vocab": string;
  /**
   *
   * @type {string}
   * @memberof BrainResponseDTOJsonldContextOneOf
   */
  hydra: BrainResponseDTOJsonldContextOneOfHydraEnum;
}

export const BrainResponseDTOJsonldContextOneOfHydraEnum = {
  HttpWwwW3OrgNsHydraCore: "http://www.w3.org/ns/hydra/core#",
} as const;

export type BrainResponseDTOJsonldContextOneOfHydraEnum =
  (typeof BrainResponseDTOJsonldContextOneOfHydraEnum)[keyof typeof BrainResponseDTOJsonldContextOneOfHydraEnum];

/**
 *
 * @export
 * @interface LoginCheckPost200Response
 */
export interface LoginCheckPost200Response {
  /**
   *
   * @type {string}
   * @memberof LoginCheckPost200Response
   */
  token: string;
}
/**
 *
 * @export
 * @interface LoginCheckPostRequest
 */
export interface LoginCheckPostRequest {
  /**
   *
   * @type {string}
   * @memberof LoginCheckPostRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof LoginCheckPostRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface OllamaListLocalModelsDetailsDTO
 */
export interface OllamaListLocalModelsDetailsDTO {
  /**
   *
   * @type {string}
   * @memberof OllamaListLocalModelsDetailsDTO
   */
  parent_model?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaListLocalModelsDetailsDTO
   */
  format?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaListLocalModelsDetailsDTO
   */
  family?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof OllamaListLocalModelsDetailsDTO
   */
  families?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof OllamaListLocalModelsDetailsDTO
   */
  parameter_size?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaListLocalModelsDetailsDTO
   */
  quantization_level?: string;
}
/**
 *
 * @export
 * @interface OllamaListLocalModelsDetailsDTOJsonld
 */
export interface OllamaListLocalModelsDetailsDTOJsonld {
  /**
   *
   * @type {BrainResponseDTOJsonldContext}
   * @memberof OllamaListLocalModelsDetailsDTOJsonld
   */
  "@context"?: BrainResponseDTOJsonldContext;
  /**
   *
   * @type {string}
   * @memberof OllamaListLocalModelsDetailsDTOJsonld
   */
  "@id"?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaListLocalModelsDetailsDTOJsonld
   */
  "@type"?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaListLocalModelsDetailsDTOJsonld
   */
  parent_model?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaListLocalModelsDetailsDTOJsonld
   */
  format?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaListLocalModelsDetailsDTOJsonld
   */
  family?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof OllamaListLocalModelsDetailsDTOJsonld
   */
  families?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof OllamaListLocalModelsDetailsDTOJsonld
   */
  parameter_size?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaListLocalModelsDetailsDTOJsonld
   */
  quantization_level?: string;
}
/**
 *
 * @export
 * @interface OllamaOllamaGenerateInputDTO
 */
export interface OllamaOllamaGenerateInputDTO {
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaGenerateInputDTO
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaGenerateInputDTO
   */
  prompt?: string;
}
/**
 *
 * @export
 * @interface OllamaOllamaGenerateInputDTOJsonld
 */
export interface OllamaOllamaGenerateInputDTOJsonld {
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaGenerateInputDTOJsonld
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaGenerateInputDTOJsonld
   */
  prompt?: string;
}
/**
 *
 * @export
 * @interface OllamaOllamaGenerateOutputDTO
 */
export interface OllamaOllamaGenerateOutputDTO {
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaGenerateOutputDTO
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaGenerateOutputDTO
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaGenerateOutputDTO
   */
  response?: string;
  /**
   *
   * @type {boolean}
   * @memberof OllamaOllamaGenerateOutputDTO
   */
  done?: boolean;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaGenerateOutputDTO
   */
  done_reason?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof OllamaOllamaGenerateOutputDTO
   */
  context?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof OllamaOllamaGenerateOutputDTO
   */
  total_duration?: number;
  /**
   *
   * @type {number}
   * @memberof OllamaOllamaGenerateOutputDTO
   */
  load_duration?: number;
  /**
   *
   * @type {number}
   * @memberof OllamaOllamaGenerateOutputDTO
   */
  prompt_eval_count?: number | null;
  /**
   *
   * @type {number}
   * @memberof OllamaOllamaGenerateOutputDTO
   */
  prompt_eval_duration?: number;
  /**
   *
   * @type {number}
   * @memberof OllamaOllamaGenerateOutputDTO
   */
  eval_count?: number;
  /**
   *
   * @type {number}
   * @memberof OllamaOllamaGenerateOutputDTO
   */
  eval_duration?: number;
}
/**
 *
 * @export
 * @interface OllamaOllamaGenerateOutputDTOJsonld
 */
export interface OllamaOllamaGenerateOutputDTOJsonld {
  /**
   *
   * @type {BrainResponseDTOJsonldContext}
   * @memberof OllamaOllamaGenerateOutputDTOJsonld
   */
  "@context"?: BrainResponseDTOJsonldContext;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaGenerateOutputDTOJsonld
   */
  "@id"?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaGenerateOutputDTOJsonld
   */
  "@type"?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaGenerateOutputDTOJsonld
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaGenerateOutputDTOJsonld
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaGenerateOutputDTOJsonld
   */
  response?: string;
  /**
   *
   * @type {boolean}
   * @memberof OllamaOllamaGenerateOutputDTOJsonld
   */
  done?: boolean;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaGenerateOutputDTOJsonld
   */
  done_reason?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof OllamaOllamaGenerateOutputDTOJsonld
   */
  context?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof OllamaOllamaGenerateOutputDTOJsonld
   */
  total_duration?: number;
  /**
   *
   * @type {number}
   * @memberof OllamaOllamaGenerateOutputDTOJsonld
   */
  load_duration?: number;
  /**
   *
   * @type {number}
   * @memberof OllamaOllamaGenerateOutputDTOJsonld
   */
  prompt_eval_count?: number | null;
  /**
   *
   * @type {number}
   * @memberof OllamaOllamaGenerateOutputDTOJsonld
   */
  prompt_eval_duration?: number;
  /**
   *
   * @type {number}
   * @memberof OllamaOllamaGenerateOutputDTOJsonld
   */
  eval_count?: number;
  /**
   *
   * @type {number}
   * @memberof OllamaOllamaGenerateOutputDTOJsonld
   */
  eval_duration?: number;
}
/**
 *
 * @export
 * @interface OllamaOllamaListLocalModelsDTO
 */
export interface OllamaOllamaListLocalModelsDTO {
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaListLocalModelsDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaListLocalModelsDTO
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaListLocalModelsDTO
   */
  modified_at?: string;
  /**
   *
   * @type {number}
   * @memberof OllamaOllamaListLocalModelsDTO
   */
  size?: number;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaListLocalModelsDTO
   */
  digest?: string;
  /**
   *
   * @type {OllamaListLocalModelsDetailsDTO}
   * @memberof OllamaOllamaListLocalModelsDTO
   */
  details?: OllamaListLocalModelsDetailsDTO;
}
/**
 *
 * @export
 * @interface OllamaOllamaListLocalModelsDTOJsonld
 */
export interface OllamaOllamaListLocalModelsDTOJsonld {
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaListLocalModelsDTOJsonld
   */
  "@id"?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaListLocalModelsDTOJsonld
   */
  "@type"?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaListLocalModelsDTOJsonld
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaListLocalModelsDTOJsonld
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaListLocalModelsDTOJsonld
   */
  modified_at?: string;
  /**
   *
   * @type {number}
   * @memberof OllamaOllamaListLocalModelsDTOJsonld
   */
  size?: number;
  /**
   *
   * @type {string}
   * @memberof OllamaOllamaListLocalModelsDTOJsonld
   */
  digest?: string;
  /**
   *
   * @type {OllamaListLocalModelsDetailsDTOJsonld}
   * @memberof OllamaOllamaListLocalModelsDTOJsonld
   */
  details?: OllamaListLocalModelsDetailsDTOJsonld;
}
/**
 *
 * @export
 * @interface RefreshTokenRefreshTokenDTO
 */
export interface RefreshTokenRefreshTokenDTO {
  /**
   *
   * @type {string}
   * @memberof RefreshTokenRefreshTokenDTO
   */
  token?: string | null;
}
/**
 *
 * @export
 * @interface RefreshTokenRefreshTokenDTOJsonld
 */
export interface RefreshTokenRefreshTokenDTOJsonld {
  /**
   *
   * @type {BrainResponseDTOJsonldContext}
   * @memberof RefreshTokenRefreshTokenDTOJsonld
   */
  "@context"?: BrainResponseDTOJsonldContext;
  /**
   *
   * @type {string}
   * @memberof RefreshTokenRefreshTokenDTOJsonld
   */
  "@id"?: string;
  /**
   *
   * @type {string}
   * @memberof RefreshTokenRefreshTokenDTOJsonld
   */
  "@type"?: string;
  /**
   *
   * @type {string}
   * @memberof RefreshTokenRefreshTokenDTOJsonld
   */
  token?: string | null;
}

/**
 * BrainApi - axios parameter creator
 * @export
 */
export const BrainApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Removes the Brain resource.
     * @summary Removes the Brain resource.
     * @param {string} uuid Brain identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBrainForgetUuidDelete: async (
      uuid: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("apiBrainForgetUuidDelete", "uuid", uuid);
      const localVarPath = `/api/brain_forget/{uuid}`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves the collection of Brain resources.
     * @summary Retrieves the collection of Brain resources.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBrainKnowGetCollection: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/brain_know`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates a Brain resource.
     * @summary Creates a Brain resource.
     * @param {BrainBrainLearnDTO} brainBrainLearnDTO The new Brain resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBrainLearnPost: async (
      brainBrainLearnDTO: BrainBrainLearnDTO,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'brainBrainLearnDTO' is not null or undefined
      assertParamExists(
        "apiBrainLearnPost",
        "brainBrainLearnDTO",
        brainBrainLearnDTO,
      );
      const localVarPath = `/api/brain_learn`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        brainBrainLearnDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BrainApi - functional programming interface
 * @export
 */
export const BrainApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BrainApiAxiosParamCreator(configuration);
  return {
    /**
     * Removes the Brain resource.
     * @summary Removes the Brain resource.
     * @param {string} uuid Brain identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiBrainForgetUuidDelete(
      uuid: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiBrainForgetUuidDelete(uuid, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["BrainApi.apiBrainForgetUuidDelete"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Retrieves the collection of Brain resources.
     * @summary Retrieves the collection of Brain resources.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiBrainKnowGetCollection(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<BrainBrainKnowDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiBrainKnowGetCollection(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["BrainApi.apiBrainKnowGetCollection"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Creates a Brain resource.
     * @summary Creates a Brain resource.
     * @param {BrainBrainLearnDTO} brainBrainLearnDTO The new Brain resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiBrainLearnPost(
      brainBrainLearnDTO: BrainBrainLearnDTO,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BrainResponseDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiBrainLearnPost(
          brainBrainLearnDTO,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["BrainApi.apiBrainLearnPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * BrainApi - factory interface
 * @export
 */
export const BrainApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BrainApiFp(configuration);
  return {
    /**
     * Removes the Brain resource.
     * @summary Removes the Brain resource.
     * @param {string} uuid Brain identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBrainForgetUuidDelete(uuid: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .apiBrainForgetUuidDelete(uuid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieves the collection of Brain resources.
     * @summary Retrieves the collection of Brain resources.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBrainKnowGetCollection(
      options?: any,
    ): AxiosPromise<Array<BrainBrainKnowDTO>> {
      return localVarFp
        .apiBrainKnowGetCollection(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Creates a Brain resource.
     * @summary Creates a Brain resource.
     * @param {BrainBrainLearnDTO} brainBrainLearnDTO The new Brain resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBrainLearnPost(
      brainBrainLearnDTO: BrainBrainLearnDTO,
      options?: any,
    ): AxiosPromise<BrainResponseDTO> {
      return localVarFp
        .apiBrainLearnPost(brainBrainLearnDTO, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * BrainApi - object-oriented interface
 * @export
 * @class BrainApi
 * @extends {BaseAPI}
 */
export class BrainApi extends BaseAPI {
  /**
   * Removes the Brain resource.
   * @summary Removes the Brain resource.
   * @param {string} uuid Brain identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrainApi
   */
  public apiBrainForgetUuidDelete(
    uuid: string,
    options?: RawAxiosRequestConfig,
  ) {
    return BrainApiFp(this.configuration)
      .apiBrainForgetUuidDelete(uuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieves the collection of Brain resources.
   * @summary Retrieves the collection of Brain resources.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrainApi
   */
  public apiBrainKnowGetCollection(options?: RawAxiosRequestConfig) {
    return BrainApiFp(this.configuration)
      .apiBrainKnowGetCollection(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creates a Brain resource.
   * @summary Creates a Brain resource.
   * @param {BrainBrainLearnDTO} brainBrainLearnDTO The new Brain resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrainApi
   */
  public apiBrainLearnPost(
    brainBrainLearnDTO: BrainBrainLearnDTO,
    options?: RawAxiosRequestConfig,
  ) {
    return BrainApiFp(this.configuration)
      .apiBrainLearnPost(brainBrainLearnDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * LoginCheckApi - axios parameter creator
 * @export
 */
export const LoginCheckApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Creates a user token.
     * @summary Creates a user token.
     * @param {LoginCheckPostRequest} loginCheckPostRequest The login data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loginCheckPost: async (
      loginCheckPostRequest: LoginCheckPostRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'loginCheckPostRequest' is not null or undefined
      assertParamExists(
        "loginCheckPost",
        "loginCheckPostRequest",
        loginCheckPostRequest,
      );
      const localVarPath = `/auth`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginCheckPostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LoginCheckApi - functional programming interface
 * @export
 */
export const LoginCheckApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    LoginCheckApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates a user token.
     * @summary Creates a user token.
     * @param {LoginCheckPostRequest} loginCheckPostRequest The login data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async loginCheckPost(
      loginCheckPostRequest: LoginCheckPostRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LoginCheckPost200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.loginCheckPost(
        loginCheckPostRequest,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["LoginCheckApi.loginCheckPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * LoginCheckApi - factory interface
 * @export
 */
export const LoginCheckApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LoginCheckApiFp(configuration);
  return {
    /**
     * Creates a user token.
     * @summary Creates a user token.
     * @param {LoginCheckPostRequest} loginCheckPostRequest The login data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loginCheckPost(
      loginCheckPostRequest: LoginCheckPostRequest,
      options?: any,
    ): AxiosPromise<LoginCheckPost200Response> {
      return localVarFp
        .loginCheckPost(loginCheckPostRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * LoginCheckApi - object-oriented interface
 * @export
 * @class LoginCheckApi
 * @extends {BaseAPI}
 */
export class LoginCheckApi extends BaseAPI {
  /**
   * Creates a user token.
   * @summary Creates a user token.
   * @param {LoginCheckPostRequest} loginCheckPostRequest The login data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginCheckApi
   */
  public loginCheckPost(
    loginCheckPostRequest: LoginCheckPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LoginCheckApiFp(this.configuration)
      .loginCheckPost(loginCheckPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OllamaApi - axios parameter creator
 * @export
 */
export const OllamaApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Creates a Ollama resource.
     * @summary Creates a Ollama resource.
     * @param {OllamaOllamaGenerateInputDTO} ollamaOllamaGenerateInputDTO The new Ollama resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOllamaGeneratePost: async (
      ollamaOllamaGenerateInputDTO: OllamaOllamaGenerateInputDTO,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ollamaOllamaGenerateInputDTO' is not null or undefined
      assertParamExists(
        "apiOllamaGeneratePost",
        "ollamaOllamaGenerateInputDTO",
        ollamaOllamaGenerateInputDTO,
      );
      const localVarPath = `/api/ollama_generate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        ollamaOllamaGenerateInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves the collection of Ollama resources.
     * @summary Retrieves the collection of Ollama resources.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOllamaListLocalModelsGetCollection: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/ollama_list_local_models`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OllamaApi - functional programming interface
 * @export
 */
export const OllamaApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OllamaApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates a Ollama resource.
     * @summary Creates a Ollama resource.
     * @param {OllamaOllamaGenerateInputDTO} ollamaOllamaGenerateInputDTO The new Ollama resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiOllamaGeneratePost(
      ollamaOllamaGenerateInputDTO: OllamaOllamaGenerateInputDTO,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OllamaOllamaGenerateOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiOllamaGeneratePost(
          ollamaOllamaGenerateInputDTO,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OllamaApi.apiOllamaGeneratePost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Retrieves the collection of Ollama resources.
     * @summary Retrieves the collection of Ollama resources.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiOllamaListLocalModelsGetCollection(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<OllamaOllamaListLocalModelsDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiOllamaListLocalModelsGetCollection(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OllamaApi.apiOllamaListLocalModelsGetCollection"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * OllamaApi - factory interface
 * @export
 */
export const OllamaApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OllamaApiFp(configuration);
  return {
    /**
     * Creates a Ollama resource.
     * @summary Creates a Ollama resource.
     * @param {OllamaOllamaGenerateInputDTO} ollamaOllamaGenerateInputDTO The new Ollama resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOllamaGeneratePost(
      ollamaOllamaGenerateInputDTO: OllamaOllamaGenerateInputDTO,
      options?: any,
    ): AxiosPromise<OllamaOllamaGenerateOutputDTO> {
      return localVarFp
        .apiOllamaGeneratePost(ollamaOllamaGenerateInputDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieves the collection of Ollama resources.
     * @summary Retrieves the collection of Ollama resources.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOllamaListLocalModelsGetCollection(
      options?: any,
    ): AxiosPromise<Array<OllamaOllamaListLocalModelsDTO>> {
      return localVarFp
        .apiOllamaListLocalModelsGetCollection(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OllamaApi - object-oriented interface
 * @export
 * @class OllamaApi
 * @extends {BaseAPI}
 */
export class OllamaApi extends BaseAPI {
  /**
   * Creates a Ollama resource.
   * @summary Creates a Ollama resource.
   * @param {OllamaOllamaGenerateInputDTO} ollamaOllamaGenerateInputDTO The new Ollama resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OllamaApi
   */
  public apiOllamaGeneratePost(
    ollamaOllamaGenerateInputDTO: OllamaOllamaGenerateInputDTO,
    options?: RawAxiosRequestConfig,
  ) {
    return OllamaApiFp(this.configuration)
      .apiOllamaGeneratePost(ollamaOllamaGenerateInputDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieves the collection of Ollama resources.
   * @summary Retrieves the collection of Ollama resources.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OllamaApi
   */
  public apiOllamaListLocalModelsGetCollection(
    options?: RawAxiosRequestConfig,
  ) {
    return OllamaApiFp(this.configuration)
      .apiOllamaListLocalModelsGetCollection(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * RefreshTokenApi - axios parameter creator
 * @export
 */
export const RefreshTokenApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Retrieves a Refresh Token resource.
     * @summary Retrieves a Refresh Token resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiRefreshTokenGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/refresh_token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RefreshTokenApi - functional programming interface
 * @export
 */
export const RefreshTokenApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    RefreshTokenApiAxiosParamCreator(configuration);
  return {
    /**
     * Retrieves a Refresh Token resource.
     * @summary Retrieves a Refresh Token resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiRefreshTokenGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RefreshTokenRefreshTokenDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiRefreshTokenGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["RefreshTokenApi.apiRefreshTokenGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * RefreshTokenApi - factory interface
 * @export
 */
export const RefreshTokenApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RefreshTokenApiFp(configuration);
  return {
    /**
     * Retrieves a Refresh Token resource.
     * @summary Retrieves a Refresh Token resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiRefreshTokenGet(
      options?: any,
    ): AxiosPromise<RefreshTokenRefreshTokenDTO> {
      return localVarFp
        .apiRefreshTokenGet(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * RefreshTokenApi - object-oriented interface
 * @export
 * @class RefreshTokenApi
 * @extends {BaseAPI}
 */
export class RefreshTokenApi extends BaseAPI {
  /**
   * Retrieves a Refresh Token resource.
   * @summary Retrieves a Refresh Token resource.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RefreshTokenApi
   */
  public apiRefreshTokenGet(options?: RawAxiosRequestConfig) {
    return RefreshTokenApiFp(this.configuration)
      .apiRefreshTokenGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
