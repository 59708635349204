// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.L-Container {
  position: absolute;
  top: 50px;
  left: 50px;
  right: 50px;
}

.L-Input-Username {
  position: absolute;
  padding: 10px;
  font-size: 12px;
  font-weight: 600;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 3px;
  opacity: 0.7;
}

.L-Input-Password {
  position: absolute;
  padding: 10px;
  font-size: 12px;
  font-weight: 600;
  top: 45px;
  left: 0;
  right: 0;
  border-radius: 3px;
  opacity: 0.7;
}

.L-Button {
  position: absolute;
  padding: 10px;
  font-size: 12px;
  top: 91px;
  right: 0;
  width: 200px;
  font-weight: 600;
  border-radius: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Login.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,SAAS;EACT,QAAQ;EACR,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".L-Container {\n  position: absolute;\n  top: 50px;\n  left: 50px;\n  right: 50px;\n}\n\n.L-Input-Username {\n  position: absolute;\n  padding: 10px;\n  font-size: 12px;\n  font-weight: 600;\n  top: 0;\n  left: 0;\n  right: 0;\n  border-radius: 3px;\n  opacity: 0.7;\n}\n\n.L-Input-Password {\n  position: absolute;\n  padding: 10px;\n  font-size: 12px;\n  font-weight: 600;\n  top: 45px;\n  left: 0;\n  right: 0;\n  border-radius: 3px;\n  opacity: 0.7;\n}\n\n.L-Button {\n  position: absolute;\n  padding: 10px;\n  font-size: 12px;\n  top: 91px;\n  right: 0;\n  width: 200px;\n  font-weight: 600;\n  border-radius: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
