import React, { useRef, useState } from "react";
import "../styles/Login.css";
import { useTranslation } from "react-i18next";
import { useSoniAiBackendLogin } from "../apis/ollama/SoniAiBackendLogin";
import { ClipLoader } from "react-spinners";
import StorageKeys from "../enums/StorageKeys";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [executeLogin, setExecuteLogin] = useState<boolean>(false);
  const [username, setUsername] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const usernameRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);

  const { data, isLoading, error } = useSoniAiBackendLogin({
    execute: executeLogin,
    basePath: process.env.REACT_APP_SONI_AI_BACKEND,
    email: username ?? "",
    password: password ?? "",
  });

  const loginClicked = () => {
    setUsername(usernameRef.current?.value);
    setPassword(passwordRef.current?.value);
    setExecuteLogin(true);
  };

  if (data !== null) {
    if (data.token !== undefined) {
      localStorage.setItem(StorageKeys.JwtToken, data.token);
      navigate("/");
    }
  }

  if (error !== null) {
    toast.error(error);
  }

  return (
    <div className="L-Container">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        theme="colored"
        transition={Bounce}
      />

      {isLoading && (
        <ClipLoader className="SB-Loader" color="white" size={25} />
      )}
      {!isLoading && (
        <div>
          <input
            ref={usernameRef}
            className="L-Input-Username"
            placeholder={t("login.username-placeholder")}
            type="text"
          />
          <input
            ref={passwordRef}
            className="L-Input-Password"
            placeholder={t("login.password-placeholder")}
            type="password"
          />
          <button className="L-Button" onClick={loginClicked}>
            {t("login.button")}
          </button>
        </div>
      )}
    </div>
  );
}
