import { useEffect, useState } from "react";
import StorageKeys from "../../enums/StorageKeys";
import { RefreshTokenApi } from "../../generated/soniAiBackend";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface SoniAiBackendProperties {
  execute: boolean;
  basePath: string | undefined | null;
  apiCall: any;
  options: object;
  uriVariables?: string[];
}

export const useSoniAiBackend = <T>(props: SoniAiBackendProperties) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const refreshToken = () => {
      const token = localStorage.getItem(StorageKeys.JwtToken);
      const parts = token?.split(".");

      if (parts !== undefined && parts.length === 3) {
        const tokenPayload = JSON.parse(atob(parts[1]));
        const now = Math.ceil(Date.now() / 1000);
        const diff = tokenPayload.exp - now;

        if (diff > 0 && diff < 120) {
          const aliceBackendCollectorApiTokenRefresh = new RefreshTokenApi(
            undefined,
            props.basePath ?? "",
          );
          aliceBackendCollectorApiTokenRefresh
            .apiRefreshTokenGet({
              headers: { Authorization: "Bearer " + token },
            })
            .then((response) =>
              localStorage.setItem(
                StorageKeys.JwtToken,
                response.data.token ?? "",
              ),
            )
            .catch(() => localStorage.removeItem(StorageKeys.JwtToken));
        }
      } else {
        localStorage.removeItem(StorageKeys.JwtToken);
      }
    };

    const fetchData = async () => {
      setIsLoading(true);

      try {
        let response;
        if (props.uriVariables === undefined) {
          response = await props.apiCall(props.options);
          setData(response.data);
        } else {
          response = await props.apiCall(...props.uriVariables, props.options);
          setData(response.data);
        }
      } catch (error: any) {
        if (error.response?.data?.code === 401) {
          localStorage.removeItem(StorageKeys.JwtToken);
          navigate("login");
        } else if (error instanceof Error) {
          setError(error.message);
        } else {
          setError(JSON.stringify(error));
        }
      }

      setIsLoading(false);
    };

    if (props.execute) {
      refreshToken();

      setIsLoading(false);
      setData(null);
      setError(null);

      if (
        props.basePath !== undefined &&
        props.basePath !== null &&
        props.basePath !== ""
      ) {
        fetchData().finally(() => {
          setIsLoading(false);
        });
      } else {
        setError(t("ollama-backend.backend-url-is-empty"));
      }
    }
  }, [props.execute, navigate, props, t]);

  return { data, isLoading, error };
};
