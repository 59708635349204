import {
  OllamaApi,
  OllamaOllamaListLocalModelsDTO,
} from "../../generated/soniAiBackend";
import { useSoniAiBackend } from "./SoniAiBackend";
import StorageKeys from "../../enums/StorageKeys";

interface SoniAiBackendOllamaListLocalModelsProperties {
  execute: boolean;
  basePath: string | undefined | null;
}

export const useSoniAiBackendOllamaListLocalModels = (
  props: SoniAiBackendOllamaListLocalModelsProperties,
) => {
  const soniAiBackendOllamaLostLocalModels = new OllamaApi(
    undefined,
    props.basePath ?? "",
  );

  return useSoniAiBackend<OllamaOllamaListLocalModelsDTO[]>({
    execute: props.execute,
    basePath: props.basePath,
    apiCall:
      soniAiBackendOllamaLostLocalModels.apiOllamaListLocalModelsGetCollection,
    options: {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(StorageKeys.JwtToken),
      },
    },
  });
};
