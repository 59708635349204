import Background from "../components/Background";
import Login from "../components/Login";

const LoginPage = () => {
  return (
    <div>
      <Background />
      <Login />
    </div>
  );
};

export default LoginPage;
