import React from "react";
import "../styles/SelectInput.css";
import SelectInputProperties from "../properties/SelectInputProperties";

export default function SelectInput(props: SelectInputProperties) {
  return (
    <select
      className="SI-Select"
      value={props.value}
      onChange={(event) => props.setValue(event.target.value)}
    >
      {props.values.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </select>
  );
}
