import React, { useEffect, useState } from "react";
import StorageKeys from "../enums/StorageKeys";
import { useNavigate } from "react-router-dom";
import ChatType from "../types/ChatType";
import { MessageUser } from "../enums/MessageUser";
import { useSoniAiBackendOllamaListLocalModels } from "../apis/ollama/SoniAiBackendOllamaListLocalModels";
import { SyncLoader } from "react-spinners";
import { Bounce, toast, ToastContainer } from "react-toastify";
import Toolbar from "../components/Toolbar";
import Background from "../components/Background";
import MessageBox from "../components/MessageBox";
import "react-toastify/dist/ReactToastify.css";
import SearchBar from "../components/SearchBar";
import { useSoniAiBackendOllamaGenerate } from "../apis/ollama/SoniAiBackendOllamaGenerate";

const ChatPage = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem(StorageKeys.JwtToken);
  const [messages, setMessages] = useState<ChatType[]>([]);
  const [models, setModels] = useState<(string | undefined)[]>([]);
  const [model, setModel] = useState<string | undefined>();
  const [prompt, setPrompt] = useState<string>("");
  const [executeListLocalModels, setExecuteListLocalModels] = useState(false);
  const [executeGenerate, setExecuteGenerate] = useState(false);

  const search = (prompt: string) => {
    if (prompt.length > 0) {
      setMessages((messages) => [
        ...messages,
        {
          username: MessageUser.User.toString(),
          message: prompt,
          timestamp: Date.now(),
        },
      ]);

      setPrompt(prompt);
    }
  };

  const {
    data: dataListLocalModels,
    isLoading: isLoadingListLocalModels,
    error: errorListLocalModels,
  } = useSoniAiBackendOllamaListLocalModels({
    execute: executeListLocalModels,
    basePath: process.env.REACT_APP_SONI_AI_BACKEND,
  });

  const {
    data: dataGenerate,
    isLoading: isLoadingGenerate,
    error: errorGenerate,
  } = useSoniAiBackendOllamaGenerate({
    execute: executeGenerate,
    basePath: process.env.REACT_APP_SONI_AI_BACKEND,
    body: { model: model, prompt: prompt },
  });

  useEffect(() => {
    if (dataListLocalModels) {
      setModels(dataListLocalModels.map((item) => item.name));
      if (model === undefined && dataListLocalModels.length > 0) {
        setModel(dataListLocalModels[0].name);
      }
    }

    if (errorListLocalModels) {
      toast.error(errorListLocalModels);
    }

    setExecuteListLocalModels(false);
  }, [dataListLocalModels, errorListLocalModels, model]);

  useEffect(() => {
    if (dataGenerate) {
      if (dataGenerate.response !== "") {
        setMessages((messages) => [
          ...messages,
          {
            username: MessageUser.AI.toString(),
            message: dataGenerate.response ?? "",
            timestamp: Date.now(),
          },
        ]);
      }
    }

    if (errorGenerate) {
      toast.error(errorGenerate);
    }

    setExecuteGenerate(false);
  }, [dataGenerate, errorGenerate]);

  useEffect(() => {
    if (prompt !== "") {
      setExecuteGenerate(true);
    }
  }, [prompt]);

  useEffect(() => {
    if (executeGenerate) {
      setPrompt("");
    }
  }, [executeGenerate]);

  useEffect(() => {
    if (token === null || token === undefined || token === "") {
      navigate("login");
    } else {
      setExecuteListLocalModels(true);
    }
  }, [token, navigate]);

  return (
    <div className="App">
      {isLoadingListLocalModels && <SyncLoader />}
      {!isLoadingListLocalModels && (
        <div>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            theme="colored"
            transition={Bounce}
          />
          <Toolbar models={models} model={model} setModel={setModel} />
          <Background />
          <MessageBox messages={messages} />
          <SearchBar searchFunction={search} isLoading={isLoadingGenerate} />
        </div>
      )}
    </div>
  );
};

export default ChatPage;
